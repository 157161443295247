import { Link, Navigate } from 'react-router-dom';
import { useProtectedAuth } from '../../../../../hooks/useAuth';
import { useDocTitle } from '../../../../../../../shared/hooks/useDocTitle';
import { useEvent } from '../../../../../hooks/useEvent';
import { useTranslate } from '../../../../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../../../../hooks/useAbsolutePath';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Order, User } from '@invityou/types';
import { isAfter, parseISO } from 'date-fns';

const PENDING_APPROVAL = 2;
const IS_EXPECTED = 4;

export default function MemberArea() {
	const event = useEvent();
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const { user } = useProtectedAuth();

	useDocTitle(__('Member area'));

	const memberAreaQuery = useQuery({
		queryKey: ['member_area', prefix],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/users/memberJson`);
			return data as { ebadge_url: string | null; user: User; orders: Order[] };
		},
	});

	const ebadgeUrl = memberAreaQuery.data?.ebadge_url;

	const orders = memberAreaQuery.data?.orders ?? [];

	const godsons = memberAreaQuery.data?.user.godsons ?? [];

	if (user.status === null) {
		return <Navigate to={`${prefix}/register#start`} replace />;
	}

	return (
		<section>
			<div className="container section-container">
				{memberAreaQuery.isSuccess && (
					<div className="form">
						<h2 className="form-title">
							<ol className="breadcrumb">
								<li>
									{user.firstname !== '' || user.lastname !== '' ? (
										<>
											{user.firstname} {user.lastname}
										</>
									) : (
										user.mail
									)}
								</li>
							</ol>
						</h2>

						<ul className="list-inline list-pills">
							{event.params.ebadges_enabled &&
								user.status === IS_EXPECTED &&
								ebadgeUrl !== null && (
									<li>
										<a href={ebadgeUrl} download="ebadge.pdf">
											<i className="fa fa-id-badge" aria-hidden="true"></i>{' '}
											{__('Download my e-badge')}
										</a>
									</li>
								)}

							{orders.length > 0 && (
								<li>
									<Link to={`${prefix}/member/orders#start`}>
										<i className="fa fa-eur" aria-hidden="true"></i>{' '}
										{__('See my orders')}
									</Link>
								</li>
							)}

							{godsons.length > 0 && (
								<li>
									<Link to={`${prefix}/member/companions#start`}>
										<i className="fa fa-users" aria-hidden="true"></i>{' '}
										{__('See my companions')}
									</Link>
								</li>
							)}

							{event.openReply && !event.params.isFreezedRegistration && (
								<li>
									<Link to={`${prefix}/register#start`}>
										<i className="fa fa-file-text-o" aria-hidden="true"></i>{' '}
										{__('Modify my registration')}
									</Link>
								</li>
							)}

							{event.tickets_on_sale &&
								[IS_EXPECTED, PENDING_APPROVAL].includes(user.status) && (
									<li>
										<Link to={`${prefix}/ticketing#start`}>
											<i className="fa fa-ticket" aria-hidden="true"></i>{' '}
											{__('Go to ticketing')}
										</Link>
									</li>
								)}

							{event.openSurvey && (
								<li>
									<Link to={`${prefix}/survey#start`}>
										<i className="fa fa-file-text" aria-hidden="true"></i>{' '}
										{__('Satisfaction survey')}
									</Link>
								</li>
							)}

							<li>
								<Link to={`${prefix}/sharedfiles#start`}>
									<i className="fa fa-download" aria-hidden="true"></i>{' '}
									{__('Shared files')}
								</Link>
							</li>

							{(!event.closing_date ||
								isAfter(parseISO(event.closing_date), Date.now())) &&
								(user.status === IS_EXPECTED ||
									user.status === PENDING_APPROVAL) && (
									<li>
										<Link to={`${prefix}/member/cancel_registration#start`}>
											<i className="fa fa-ban" aria-hidden="true"></i>{' '}
											{__('Cancel my registration')}
										</Link>
									</li>
								)}
						</ul>
					</div>
				)}
			</div>
		</section>
	);
}
